export const data = [
  {
    id: "1",
    name: "15 хаус",
    category: "construction",
    description: "",
    special: "false",
    location: "unknown",
    image_type: "Консефт зураг",
    project_type: "Орон сууц & Хаус",
    floor_sqr: "24-120 мкв",
    floors: "F2 / B",
    project_date: "2023",
    client: "Иргэн",
    images: 1,
  },
  {
    id: "2",
    name: "Агуулахын барилга",
    category: "construction",
    description: "",
    special: "false",
    location: "УБ, СХД, 20-р хороо",
    image_type: "Интерьер",
    project_type: "Оффис",
    floor_sqr: "450 мкв",
    floors: "F2 / B",
    project_date: "2023",
    client: "Иргэн",
    images: 4,
  },
  {
    id: "3",
    name: "1000 машины үйлчилгээний төв",
    category: "construction",
    description: "",
    special: "false",
    location: "Сүхбаатар аймаг",
    image_type: "Консефт зураг",
    project_type: "Ерөнхий төлөвлөгөө",
    floor_sqr: "10 га",
    floors: "F2 / B",
    project_date: "2023",
    client: "Иргэн",
    images: 2,
  },
  {
    id: "4",
    name: "Хаус хотхон 2.3га",
    category: "construction",
    description: "",
    special: "true",
    location: "УБ хот, БЗД, 20-р хороо",
    image_type: "Консефт зураг",
    project_type: "Ерөнхий төлөвлөгөө",
    floor_sqr: "2.3 га",
    floors: "F2 / B0",
    project_date: "2023",
    client: "Иргэн",
    images: 4,
  },
  {
    id: "5",
    name: "Хаус хотхон 1.5га",
    category: "construction",
    description: "",
    special: "true",
    location: "УБ хот, СХД, 34-р хороо",
    image_type: "Консефт зураг",
    project_type: "Ерөнхий төлөвлөгөө",
    floor_sqr: "1.5 га",
    floors: "F2 / B0",
    project_date: "2022",
    client: "Иргэн",
    images: 4,
  },
  {
    id: "6",
    name: "Орон сууцны хотхон",
    category: "construction",
    description: "",
    special: "true",
    location: "УБ хот, СБД, 9-р хороо",
    image_type: "Консефт зураг",
    project_type: "Орон сууц & Хаус",
    floor_sqr: "1.1 га",
    floors: "F16 / B2",
    project_date: "2022",
    client: "Иргэн",
    images: 4,
  },
  {
    id: "7",
    name: "Үйлчилгээтэй орон сууц",
    category: "construction",
    description: "",
    special: "true",
    location: "УБ хот, БГД, 5-р хороо",
    image_type: "Консефт зураг",
    project_type: "Орон сууц",
    floor_sqr: "15000 мкв",
    floors: "F16 / B1",
    project_date: "2022",
    client: "Иргэн",
    images: 2,
  },
  {
    id: "8",
    name: "Нийтийн эзэмшилийн талбай",
    category: "construction",
    description: "",
    special: "true",
    location: "unknown",
    image_type: "Консефт зураг",
    project_type: "Орон сууц",
    floor_sqr: "1200 мкв",
    floors: "unknown",
    project_date: "2022",
    client: "Иргэн",
    images: 4,
  },
  {
    id: "9",
    name: "Сингли хаус",
    category: "construction",
    description: "",
    special: "true",
    location: "unknown",
    image_type: "Консефт зураг",
    project_type: "Орон сууц & Хаус",
    floor_sqr: "280 мкв",
    floors: "F16 / B1",
    project_date: "2022",
    client: "Иргэн",
    images: 4,
  },
  {
    id: "10",
    name: "Орон сууцны хороолол",
    category: "construction",
    description: "",
    special: "true",
    location: "УБ хот, ХУД, 23-р хороо",
    image_type: "Консефт зураг",
    project_type: "Орон сууц & Хаус",
    floor_sqr: "5 га",
    floors: "F16 / B2",
    project_date: "2021",
    client: "Иргэн",
    images: 4,
  },
  {
    id: "11",
    name: "Үйлчилгээтэй орон сууц",
    category: "construction",
    description: "",
    special: "true",
    location: "УБ хот, СБД, 1-р хороо",
    image_type: "Консефт зураг",
    project_type: "Орон сууц & Хаус",
    floor_sqr: "35000 мкв",
    floors: "F16 / B2",
    project_date: "2020",
    client: "Монполимет",
    images: 4,
  },
  {
    id: "12",
    name: "Спорт заал",
    category: "construction",
    description: "",
    special: "false",
    location: "Дорноговь аймаг, Өргөн сум",
    image_type: "БА зураг",
    project_type: "Спортын байгууламж",
    floor_sqr: "680 мкв",
    floors: "F1",
    project_date: "2019",
    client: "Монполимет",
    images: 2,
  },
  {
    id: "13",
    name: "Цэцэрлэг",
    category: "construction",
    description: "",
    special: "true",
    location: "Дорноговь аймаг, Өргөн сум",
    image_type: "Консефт зураг",
    project_type: "Олон нийт",
    floor_sqr: "1200 мкв",
    floors: "F2",
    project_date: "2019",
    client: "Монполимет",
    images: 4,
  },
  {
    id: "14",
    name: "Сингли Хаус",
    category: "construction",
    description: "",
    special: "false",
    location: "УБ хот, СБД, 19-р хороо",
    image_type: "БА зураг",
    project_type: "Орон сууц & Хаус",
    floor_sqr: "47 мкв",
    floors: "F1",
    project_date: "2019",
    client: "Иргэн",
    images: 4,
  },
  {
    id: "15",
    name: "Химийн бодисын агуулах",
    category: "construction",
    description: "",
    special: "false",
    location: "Дорноговь аймаг, Өргөн сум",
    image_type: "БА зураг",
    project_type: "Олон нийт",
    floor_sqr: "72 мкв",
    floors: "F1",
    project_date: "2019",
    client: "Монполимет",
    images: 2,
  },
  {
    id: "16",
    name: "Park view хотхон",
    category: "construction",
    description: "",
    special: "true",
    location: "УБ хот, БЗД, 26-р хороо",
    project_type: "Орон сууц & Хаус",
    image_type: "Консефт зураг",
    floor_sqr: "2.1 га",
    floors: "F16 / B2",
    project_date: "2014",
    client: "Жигүүр гранд",
    images: 4,
  },
  {
    id: "17",
    name: "Хунгийн хотхон",
    category: "construction",
    description: "",
    special: "true",
    location: "УБ хот, ХУД, 11-р хороо",
    image_type: "Ландшафт, интерьер",
    project_type: "Орон сууц & Хаус",
    floor_sqr: "1.6 га",
    floors: "F6 / B1",
    project_date: "2011",
    client: "Жигүүр гранд",
    images: 4,
  },
  {
    id: "18",
    name: "Ангар - Чингис хаан олон улсын нисэх буудал",
    category: "construction",
    description: "",
    special: "false",
    location: "Төв аймаг, Хөшигийн хөндий",
    image_type: "3D зураг",
    project_type: "Олон нийт",
    floor_sqr: "12000 мкв",
    floors: "F1",
    project_date: "2017",
    client: "Монполимет",
    images: 2,
  },
  {
    id: "19",
    name: "Жуулчны бааз",
    category: "construction",
    description: "",
    special: "true",
    location: "УБ хот, БЗД, 11-р хороо",
    image_type: "Консефт зураг",
    project_type: "Орон сууц & Хаус",
    floor_sqr: "540 мкв",
    floors: "F2",
    project_date: "2021",
    client: "Иргэн",
    images: 4,
  },
  {
    id: "20",
    name: "Хаус хотхон",
    category: "construction",
    description: "",
    special: "false",
    location: "УБ хот, СБД, 20-р хороо",
    image_type: "Консефт зураг",
    project_type: "Орон сууц & Хаус",
    floor_sqr: "4900 мкв",
    floors: "F2",
    project_date: "2020",
    client: "Иргэн",
    images: 4,
  },
  {
    id: "21",
    name: "Оффис интерьер",
    category: "interior",
    special: "false",
    location: "УБ хот, СБД, Централ таур",
    image_type: "null",
    project_type: "null",
    floor_sqr: "280 мкв",
    floors: "null",
    project_date: "2021",
    released_date: "2021",
    client: "Иргэн",
    images: 5,
  },
  {
    id: "22",
    name: "Аураг эвент холл",
    category: "interior",
    special: "false",
    location: "УБ хот, ХУД ",
    image_type: "null",
    project_type: "null",
    floor_sqr: "1200 мкв",
    floors: "null",
    project_date: "2018",
    released_date: "2019",
    client: "Апу ХХК",
    images: 4,
  },
  {
    id: "23",
    name: "Lobby interior",
    category: "interior",
    special: "false",
    location: "УБ хот, СБД, МПМ оффис",
    image_type: "null",
    project_type: "null",
    floor_sqr: "unknown",
    floors: "null",
    project_date: "2020",
    released_date: "2020",
    client: "Монполимет",
    images: 4,
  },
  {
    id: "24",
    name: "Mandala lounge",
    category: "interior",
    special: "false",
    location: "УБ хот, СБД, МПМ оффис",
    image_type: "null",
    project_type: "null",
    floor_sqr: "unknown",
    floors: "null",
    project_date: "2019",
    released_date: "2019",
    client: "Монполимет",
    images: 4,
  },
  {
    id: "25",
    name: "Хурлын өрөө",
    category: "interior",
    special: "false",
    location: "УБ хот, СБД, МПМ оффис",
    image_type: "null",
    project_type: "null",
    floor_sqr: "unknown",
    floors: "null",
    project_date: "2019",
    released_date: "2019",
    client: "Монполимет",
    images: 3,
  },
  {
    id: "26",
    name: "BBQ chicken pub",
    category: "interior",
    special: "false",
    location: "Дархан хот",
    image_type: "null",
    project_type: "null",
    floor_sqr: "unknown",
    floors: "null",
    project_date: "2018",
    released_date: "2018",
    client: "Иргэн",
    images: 6,
  },
  {
    id: "27",
    name: "Гэгээнтэн молл",
    category: "interior",
    special: "false",
    location: "УБ хот, СБД",
    image_type: "null",
    project_type: "null",
    floor_sqr: "unknown",
    floors: "null",
    project_date: "2013",
    released_date: "2013",
    client: "Жигүүр гранд",
    images: 4,
  },
  {
    id: "28",
    name: "Жи-Мобайл ХХК оффис",
    category: "interior",
    special: "false",
    location: "УБ хот, ЧД",
    image_type: "null",
    project_type: "null",
    floor_sqr: "unknown",
    floors: "null",
    project_date: "2021",
    released_date: "2022",
    client: "Техникийн алба",
    images: 4,
  },
  {
    id: "29",
    name: "VIP room",
    category: "interior",
    special: "false",
    location: "УБ хот, СБД",
    image_type: "null",
    project_type: "null",
    floor_sqr: "unknown",
    floors: "null",
    project_date: "2019",
    released_date: "2019",
    client: "Мандала лоунж",
    images: 3,
  },
  {
    id: "30",
    name: "Coffee shop",
    category: "interior",
    special: "false",
    location: "УБ хот, СБД",
    image_type: "null",
    project_type: "null",
    floor_sqr: "unknown",
    floors: "null",
    project_date: "2020",
    released_date: "2020",
    client: "Монполимет",
    images: 4,
  },
  {
    id: "31",
    name: "MMA клуб",
    category: "interior",
    special: "false",
    location: "УБ хот, ХУД",
    image_type: "null",
    project_type: "null",
    floor_sqr: "unknown",
    floors: "null",
    project_date: "2020",
    released_date: "2020",
    client: "Иргэн",
    images: 4,
  },
  {
    id: "32",
    name: "",
    category: "interior",
    special: "false",
    location: "УБ хот, СХД",
    image_type: "Интерьер",
    project_type: "Лоунж",
    floor_sqr: "100 мкв",
    floors: "null",
    project_date: "2023",
    released_date: "",
    client: "Иргэн",
    images: 4,
  },
  {
    id: "33",
    name: "240 хүүхдийн цэцэрлэг 300 хүүхдийн бага сургуулийн цогцолбор",
    category: "construction",
    special: "false",
    location: "УБ хот, ХУД 24-р хороо",
    image_type: "Консефц зураг",
    project_type: "Олон нийт",
    floor_sqr: "1296 мкв & 1848 мкв",
    floors: "F3/B1",
    project_date: "2024",
    released_date: "",
    client: "Иргэн",
    images: 3,
  },
  {
    id: "34",
    name: "Үйлдвэрлэлийн процесс харуулсан animation video",
    category: "construction",
    special: "false",
    location: "",
    image_type: "Консефт зураг",
    project_type: "Олон нийт",
    floor_sqr: "",
    floors: "",
    project_date: "2024",
    released_date: "",
    client: "NCD precon ХХК",
    images: 4,
  },
  {
    id: "35",
    name: "Riverside үйлчилгээтэй орон сууцны фасад дизайн",
    category: "construction",
    special: "false",
    location: "УБ хот, ХУД 20-р хороо",
    image_type: "Консефт зураг",
    project_type: "Орон сууц & Хаус",
    floor_sqr: "",
    floors: "F12/B1",
    project_date: "2024",
    released_date: "",
    client: "Инфинит Централ Пойнт ХХК",
    images: 5,
  },
];

export const types = [
  {
    label: "Төрөл",
    value: "Төрөл",
  },
  {
    label: "Хот, суурин төлөвлөлт",
    value: "Хот, суурин төлөвлөлт",
  },
  {
    label: "Ерөнхий төлөвлөгөө",
    value: "Ерөнхий төлөвлөгөө",
  },
  {
    label: "Орон сууц & House",
    value: "Орон сууц & House",
  },

  {
    label: "Office & Олон нийт",
    value: "Office & Олон нийт",
  },
  {
    label: "Зочид буудал & Амралтын газар",
    value: "Зочид буудал & Амралтын газар",
  },
  {
    label: "Интерьер",
    value: "Интерьер",
  },
];

export const years = [
  {
    label: "Огноо",
    value: "all",
  },
  {
    label: "2015",
    value: "2015",
  },
  {
    label: "2016",
    value: "2016",
  },
  {
    label: "2017",
    value: "2017",
  },
  {
    label: "2018",
    value: "2018",
  },
  {
    label: "2019",
    value: "2019",
  },
  {
    label: "2020",
    value: "2020",
  },
  {
    label: "2021",
    value: "2021",
  },
  {
    label: "2022",
    value: "2022",
  },
  {
    label: "2023",
    value: "2023",
  },
];

export const about = {
  title: "Бидний тухай",
  description:
    "Бид эрхэм захиалагчидтай хамтран ирээдүй хойч үедээ архитектурын гоо зүй, орон зайн хэрэгцээг хангасан уран барилгуудыг өвлүүлнэ.",
};
export const projects = {
  title: "Гүйцэтгэсэн төслүүд",
};
export const services = {
  title: "Үйлчилгээ",
  description: "",
  subtitle1: "Архитектур",
  description1: "",
  // subtitle2: "Зураг төсөл",
  description2: "",
  // subtitle3: "Хот төлөвлөлт",
  description3: "",
};
export const footer = {
  address: "ХАЯГ: 304, Барилга корпораци оффис, 4-р хороо, ЧД, УБ хот",
  phone: "УТАС: +976-91098090",
  copyright: "SATES architecture LLC. © Зохиогчийн эрх хуулиар хамгаалагдсан",
};
